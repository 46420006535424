import React, {createContext,useState, useEffect} from 'react';
import Axios from "axios";

export const RecetasContext = createContext();

const RecetasProvider = (props) => {

    const [consultar, guardarConsultar] = useState(false);
    const [recetas, guardarRecetas] = useState([]);
    const [busqueda, buscarRecetas] = useState({
        nombre: '',
        categoria: ''
    });

    useEffect((e) => {
        if (consultar){
            const obtenerRecetas = async () => {
                const url = `https://www.thecocktaildb.com/api/json/v1/1/filter.php?i=${busqueda.nombre}&c=${busqueda.categoria}`;
                const resultado = await Axios.get(url);
                guardarRecetas(resultado.data.drinks);
            };
            obtenerRecetas();
        }
    }, [busqueda,consultar])


    return (
        <RecetasContext.Provider
            value={{
                buscarRecetas,
                guardarConsultar,
                recetas
            }}
        >
            {props.children}
        </RecetasContext.Provider>
    );
}

export default RecetasProvider;