import React, {createContext, useEffect, useState} from 'react';
import axios from 'axios';

export const ModalContext = createContext();

const ModalProvider = (props) => {
    //state del provider
    const [idreceta, guardarIdReceta] = useState(null);
    const [informacionReceta, guardarReceta] = useState({});

    //una vez tenemos una receta, llamar a la API
    useEffect(() => {
        if (!idreceta) return;

        const obtenerReceta = async () => {
            const url = `https://www.thecocktaildb.com/api/json/v1/1/lookup.php?i=${idreceta}`;
            const resultado = await axios.get(url);

            guardarReceta(resultado.data.drinks[0]);

        }
        obtenerReceta();

    }, [idreceta])

    return (
        <ModalContext.Provider
            value={{
                informacionReceta,
                guardarIdReceta,
                guardarReceta
            }}
        >
            {props.children}
        </ModalContext.Provider>
    );
}

export default ModalProvider;
