import React from 'react';

const Header = () => {
    return (
        <header className="bg-alert">
            <h1>
                Busca Recetas de Bebidas
            </h1>
        </header>
    );
};

export default Header;