import React from "react";

import Header from "./components/Header";
import CategoriasProvider from "./context/CategoriasContext";
import RecetasProvider from "./context/RecetasContext";
import ModalProvider from "./context/ModalContext";

import Formulario from "./components/Formulario";
import ListaRecetas from "./components/ListaRecetas";


function App() {

    return (
        <CategoriasProvider>
            <RecetasProvider>
                <ModalProvider>
                    <Header/>

                    <div className="container mt-5">
                        <div className="row">
                            <Formulario/>
                        </div>

                        <ListaRecetas/>
                    </div>
                </ModalProvider>
            </RecetasProvider>
        </CategoriasProvider>
    );
}

export default App;
